<template>
  <div>
    <PageIntroduce
      :title="'制度流程'"
      :desc="'企业应按法律法规设定个人信息保护和处理过程中的各项流程和规范制度。'"
      :tips="'中国个人信息保护法 第五十一条'"
    />
    <b-row class="match-height">
      <b-col md="3">
        <b-card>
          <div class="filter-wrap flex-between">
            <h4>文档目录</h4>
            <el-button
              v-if="saveParentDir"
              size="mini"
              type="primary"
              icon="el-icon-plus"
              @click="openClassSaveDialog(1,null)"
            >
              新增根目录
            </el-button>
          </div>
          <div :style="{height: pageH/2 - 180 + 'px',overflowY: 'scroll'}">
            <el-tree
              ref="elTree"
              :highlight-current="true"
              :data="treeData"
              node-key="id"
              :expand-on-click-node="false"
              @current-change="treeNodeClick"
            >
              <span
                slot-scope="{ node, data }"
                class="field-tree-node"
              >
                <span>{{ node.label }}</span>
                <span class="eltree-operate tree-operate">
                  <el-link
                    v-if="saveChildDir"
                    v-show="node.level < 4"
                    :underline="false"
                    class="mr10"
                    type="primary"
                    @click.stop="openClassSaveDialog(1,data)"
                  >
                    <el-tooltip
                      content="新增"
                      placement="top"
                      effect="light"
                    >
                      <i class="el-icon-circle-plus-outline" />
                    </el-tooltip>
                  </el-link>
                  <el-link
                    v-if="updateDir"
                    :underline="false"
                    class="mr10"
                    type="primary"
                    @click.stop="openClassSaveDialog(2,data)"
                  >
                    <el-tooltip
                      content="修改"
                      placement="top"
                      effect="light"
                    >
                      <i class="el-icon-edit" />
                    </el-tooltip>
                  </el-link>
                  <el-link
                    v-if="delDir"
                    :underline="false"
                    type="primary"
                    @click.stop="deleteTree(data,node)"
                  >
                    <el-tooltip
                      content="删除"
                      placement="top"
                      effect="light"
                    >
                      <i class="el-icon-delete" />
                    </el-tooltip>
                  </el-link>
                </span>
              </span>
            </el-tree>
          </div>
        </b-card>
        <b-card style="margin-top: 10px;">
          <div class="filter-wrap flex-between">
            <h4>标签</h4>
            <el-button
              v-if="saveLabelPerm"
              size="mini"
              type="primary"
              icon="el-icon-plus"
              @click="openLabelDialog"
            >
              新增标签
            </el-button>
          </div>
          <div
            :style="{height: pageH/2 - 170 + 'px',overflowY: 'scroll'}"
            style="padding-top: 10px;margin-right: -10px;margin-bottom: -10px;"
          >
            <el-tag
              v-for="label in labelData"
              :key="label.id"
              :closable="delLabelPerm"
              :class="label.check && 'tag-active'"
              style="margin-right: 10px;margin-bottom:10px;cursor: pointer;"
              closable
              @click.native="labelClick(label)"
              @close="delLabel(label)"
            >
              {{ label.labelName }}
            </el-tag>
          </div>
        </b-card>
      </b-col>
      <b-col md="9">
        <el-card class="box-card">
          <div class="filter-wrap flex-between">
            <div class="flex-start search-filter-wrap">
              <el-input
                v-model="listQuery.SearchStr"
                width="180"
                placeholder="请输入内容"
                clearable
                @clear="handleFilter"
              />
              <el-button
                size="small"
                type="primary"
                icon="el-icon-search"
                @click="handleFilter"
              >
                搜索
              </el-button>
            </div>
            <div>
              <el-button
                v-show="saveRegime"
                size="small"
                type="primary"
                icon="el-icon-upload"
                @click="saveDialogShow()"
              >
                导入
              </el-button>
              <el-button
                v-show="saveRegime"
                size="small"
                type="primary"
                icon="el-icon-plus"
                @click="regimeSave(1)"
              >
                新增
              </el-button>
            </div>
          </div>
          <el-table
            v-loading="loading"
            border
            fit
            highlight-current-row
            :data="tableData"
            stripe
            style="width: 100%"
          >
            <el-table-column
              prop="name"
              :class-name="'leftText'"
              :show-overflow-tooltip="true"
              label="制度名称"
            />
            <el-table-column
              prop="path"
              :show-overflow-tooltip="true"
              width="100"
              label="文档目录"
            />
            <el-table-column
              prop="label"
              width="100"
              :show-overflow-tooltip="true"
              label="标签"
            >
              <template
                slot="header"
                slot-scope="scope"
              >
                <el-dropdown class="table-header-dropdown">
                  <span class="header-dropdown-title">标签<i class="el-icon-arrow-down el-icon--right" /></span>
                  <el-dropdown-menu slot="dropdown">
                    <VuePerfectScrollbar class="dropdown-item-wrap">
                      <el-dropdown-item
                        v-for="item in labelData"
                        :key="item.id"
                        :class="item.check && 'tag-active'"
                        @click.native="labelClick(item)"
                      >{{ item.labelName }}</el-dropdown-item>
                    </VuePerfectScrollbar>

                  </el-dropdown-menu>
                </el-dropdown>
              </template>
              <template slot-scope="{row}">
                {{ row.regimeLabelList.map( label => label.labelName).join(',') }}
              </template>
            </el-table-column>
            <el-table-column
              prop="regimeType"
              width="50"
              label="来源"
            >
              <template slot-scope="{row}">
                {{ row.regimeType === 2 ? '导入' : '模板' }}
              </template>
            </el-table-column>
            <el-table-column
              prop="description"
              :show-overflow-tooltip="true"
              label="制度说明"
            />

            <el-table-column
              prop="createTime"
              label="发布时间"
              width="100"
            >
              <template slot-scope="{row}">
                {{ row.createTime | formatDate }}
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="165px"
            >
              <template slot-scope="{row}">
                <el-link
                  v-show="updateRegime && row.regimeType === 1"
                  class="mr10"
                  :underline="false"
                  type="primary"
                  @click="regimeSave(2, row.id)"
                >
                  <el-tooltip
                    content="复制"
                    placement="top"
                    effect="light"
                  >
                    <i class="el-icon-document-copy" />
                  </el-tooltip>
                </el-link>
                <el-link
                  v-show="updateRegime"
                  class="mr10"
                  :underline="false"
                  type="primary"
                  @click="row.regimeType === 2 ? saveDialogShow(row) : regimeSave(3,row.id)"
                >
                  <el-tooltip
                    content="修改"
                    placement="top"
                    effect="light"
                  >
                    <i class="el-icon-edit" />
                  </el-tooltip>
                </el-link>
                <el-link
                  v-show="readRegime"
                  :underline="false"
                  class="mr10"
                  type="primary"
                  @click="preview(row)"
                >
                  <el-tooltip
                    content="预览"
                    placement="top"
                    effect="light"
                  >
                    <i class="el-icon-document" />
                  </el-tooltip>
                </el-link>
                <el-link
                  v-show="downloadRegime"
                  :underline="false"
                  class="mr10"
                  type="primary"
                  @click="downloadFile(row.id)"
                >
                  <el-tooltip
                    content="下载"
                    placement="top"
                    effect="light"
                  >
                    <i class="el-icon-download" />
                  </el-tooltip>
                </el-link>
                <el-link
                  v-show="delRegime"
                  :underline="false"
                  type="primary"
                  @click="delAssest(row.id)"
                >
                  <el-tooltip
                    content="删除"
                    placement="top"
                    effect="light"
                  >
                    <i class="el-icon-delete" />
                  </el-tooltip>
                </el-link>
              </template>
            </el-table-column>
          </el-table>
          <!--<pagination v-show="total>0"-->
          <!--:total="total"-->
          <!--:page.sync="listQuery.page"-->
          <!--:limit.sync="listQuery.pageSize"-->
          <!--@pagination="getList" />-->
          <PageQuery
            v-show="total>0"
            :total="total"
            :cache-name="'listQuery2'"
            :child-page.sync="childPage"
            :update-state="'UPDATE_LISTQUERY2'"
            :list-query.sync="listQuery"
            :init-list-query.sync="initListQuery"
            @pagination="getList"
          />
        </el-card>
      </b-col>
    </b-row>
    <CatalogueDialog
      ref="catalogueDialog"
      @addTreeNode="addTreeNode"
      @updateTreeNode="updateTreeNode"
    />
    <LabelDialog
      ref="labelDialog"
      @saveLabel="saveLabel"
    />
    <RegimeDialog
      ref="regimeDialog"
      :labels="labelData"
      @handleFilter="handleFilter"
    />
  </div>
</template>

<script>
import pagination from '@core/components/pagination/Pagination.vue'
import CatalogueDialog from '@/views/privacyFramework/regime/CatalogueDialog.vue'
import LabelDialog from '@/views/privacyFramework/regime/LabelDialog.vue'
import RegimeDialog from '@/views/privacyFramework/regime/RegimeDialog.vue'
import {
  GetRegimeListByPage, DeleteRegimeByIds, DownloadFile, GetLabelList, GetAllLibrary, DeleteLabelByIds, DeleteLibraryById, GetRegimeById,
} from '@/api/regime/regime'
import {
  error, success, getBtnPerms, verifyBtnPerm,
} from '@core/utils/utils'
import PageIntroduce from '@core/components/pageIntroduce/PageIntroduce.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    pagination,
    CatalogueDialog,
    LabelDialog,
    RegimeDialog,
    PageIntroduce,
    VuePerfectScrollbar,
  },
  data() {
    const listQuery = {
      page: 1,
      pageSize: 10,
      SearchStr: '',
      enterpriseId: 100,
      labelIds: [],
      libraryId: 0,
    }
    return {
      childPage: [],
      accessToken: localStorage.getItem('accessToken'),
      saveParentDir: false, // 新增一级目录
      saveChildDir: false, // 新增子目录
      updateDir: false, // 修改目录
      delDir: false, // 删除目录
      saveLabelPerm: false, // 新增标签
      delLabelPerm: false, // 删除标签
      saveRegime: false, // 新增制度
      updateRegime: false, // 修改制度
      delRegime: false, // 删除制度
      downloadRegime: false, // 下载制度
      readRegime: false, // 下载制度
      loading: false,
      total: 0,
      listQuery: { ...listQuery },
      initListQuery: { ...listQuery },
      tableData: [],
      treeData: [],
      optClassData: {},
      labelData: [],
    }
  },
  created() {
    getBtnPerms(btnPerms => {
      this.saveParentDir = verifyBtnPerm(btnPerms, 'regime-dir-1') // 新增一级目录
      this.saveChildDir = verifyBtnPerm(btnPerms, 'regime-dir-2') // 新增子目录
      this.updateDir = verifyBtnPerm(btnPerms, 'regime-dir-3')// 修改目录
      this.delDir = verifyBtnPerm(btnPerms, 'regime-dir-4') // 删除目录
      this.saveLabelPerm = verifyBtnPerm(btnPerms, 'regime-label-1') // 新增标签
      this.delLabelPerm = verifyBtnPerm(btnPerms, 'regime-label-2') // 删除标签
      this.saveRegime = verifyBtnPerm(btnPerms, 'regime-1') // 新增制度
      this.updateRegime = verifyBtnPerm(btnPerms, 'regime-2') // 修改制度
      this.delRegime = verifyBtnPerm(btnPerms, 'regime-3') // 删除制度
      this.downloadRegime = verifyBtnPerm(btnPerms, 'regime-4') // 下载制度
      this.readRegime = verifyBtnPerm(btnPerms, 'regime-5') // 下载制度
    })
    const cacheQuery = this.$store.state.pageQuery.listQuery2
    this.listQuery = { ...cacheQuery }
  },
  mounted() {
    this.getList()
    this.getAllTree()
    this.getAllLabel()
  },
  methods: {
    labelClick(item) {
      if (this.listQuery.labelIds.includes(item.id)) {
        this.listQuery.labelIds = this.listQuery.labelIds.filter(id => id !== item.id)
      } else {
        this.listQuery.labelIds.push(item.id)
      }
      for (const label of this.labelData) {
        if (label.id === item.id) {
          this.$set(label, 'check', !label.check)
          break
        }
      }
      this.handleFilter()
    },
    preview(row) {
      // row.regimeType === 1
      const routeUrl = this.$router.resolve({
        path: '/regime-read', query: { id: row.id, t: row.regimeType },
      })
      window.open(routeUrl.href, '_blank')
    },
    downloadFile(id) {
      DownloadFile(id)
    },
    delAssest(id) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        const ids = []
        ids.push(id)
        DeleteRegimeByIds({ ids })
          .then(res => {
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.dialogVisible = false
              this.handleFilter()
            } else {
              error(resData.msg)
            }
          })
      }).catch(() => { })
    },
    detailDialogShow(assestId) {
      this.$router.push({
        path: '/assetDetail',
        query: {
          id: assestId,
        },
      })
    },
    importDialogShow() {
      this.$refs.importAssest.dialogVisible = true
    },
    saveDialogShow(row) {
      if (row) {
        GetRegimeById({ id: row.id }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.$refs.regimeDialog.form.id = resData.data.id
            this.$refs.regimeDialog.form.name = resData.data.name
            this.$refs.regimeDialog.form.description = resData.data.description
            this.$refs.regimeDialog.form.labelIdList = resData.data.labelIdList
            this.$refs.regimeDialog.form.fileName = resData.data.fileName
            this.$refs.regimeDialog.dialogVisible = true
            GetAllLibrary().then(res1 => {
              const resData1 = res1.data
              if (resData1.code === 0 && resData1.data && resData1.data.length > 0) {
                this.$refs.regimeDialog.folders = this.listToTree(resData1.data)
                let idPath1 = ''; let
                  idPath2 = ''
                resData1.data.forEach(item => {
                  let idPath = item.idPath.replace('0,', '') + item.id
                  idPath = idPath.split(',').map(id => Number(id))
                  if (item.idPath.indexOf(resData.data.libraryId) > 0 && idPath1.length < idPath.length) {
                    idPath1 = idPath
                  }
                  if (resData.data.libraryId === item.id) {
                    idPath2 = idPath
                  }
                })
                this.$refs.regimeDialog.form.libraryId = idPath1 || idPath2
              } else {
                this.$refs.regimeDialog.folders = []
              }
            })
          }
        })
      } else {
        this.$refs.regimeDialog.dialogVisible = true
      }
    },
    regimeSave(type, id) {
      this.$router.push({
        path: '/regime-save',
        query: {
          id,
          t: type,
        },
      })
    },
    getList() {
      this.loading = true
      GetRegimeListByPage(this.listQuery)
        .then(res => {
          this.loading = false
          this.tableData = res.data.data.list
          this.total = Number(res.data.data.total)
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    saveLabel() {
      this.getAllLabel()
    },
    delLabel(label) {
      this.$confirm(`是否确认删除该标签（${label.labelName}）?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        DeleteLabelByIds({ ids: [label.id] })
          .then(res => {
            const resData = res.data
            if (resData.code === 0) {
              this.getAllLabel()
              success(resData.msg)
              if (this.listQuery.labelIds.indexOf(label.id) !== -1) {
                this.listQuery.labelIds = this.listQuery.labelIds.filter(item => item !== label.id)
                this.getList()
              }
            } else {
              error(resData.msg)
            }
          })
      }).catch(() => { })
    },
    addTreeNode(data) {
      if (data.parentId === 0) {
        this.treeData.push(data)
      } else {
        if (!this.optClassData.children) {
          this.$set(this.optClassData, 'children', [])
        }
        this.optClassData.children.push(data)
      }
    },
    updateTreeNode(data) {
      this.optClassData.label = data.libraryName
      this.optClassData.libraryName = data.libraryName
    },
    getAllLabel() {
      const oldLabelData = this.labelData
      GetLabelList().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.labelData = resData.data
          for (const item of this.labelData) {
            item.value = item.id
            item.label = item.labelName
            oldLabelData.forEach(oldItem => {
              if (oldItem.id === item.id) {
                item.check = oldItem.check
              }
            })
          }
        }
      })
    },
    getAllTree() {
      GetAllLibrary().then(res => {
        const resData = res.data
        if (resData.code === 0 && resData.data && resData.data.length > 0) {
          this.treeData = this.listToTree(resData.data)
        }
      })
    },
    listToTree(list) {
      // hash
      const map = {}
      for (const item of list) {
        item.label = item.libraryName
        map[item.id] = item
      }
      for (const item of list) {
        if (!(item.parentId > 0)) {
          continue
        }
        const parent = map[item.parentId]
        if (parent) {
          if (parent.children === undefined) {
            parent.children = []
          }
          parent.children.push(item)
        }
      }
      return list.filter(i => i.parentId === 0)
    },
    treeNodeClick(data, node) {
      if (this.listQuery.libraryId == data.id) {
        this.listQuery.libraryId = 0
        this.$refs.elTree.setCurrentKey(null)
      } else {
        this.listQuery.libraryId = data.id
      }
      this.getList()
    },
    treeNodeMouseenter(data) {
      this.$set(data, 'is_show', true)
    },
    treeNodeMouseleave(data) {
      this.$set(data, 'is_show', false)
    },
    openLabelDialog() {
      this.$refs.labelDialog.dialogVisible = true
    },
    openClassSaveDialog(type, data) {
      this.$refs.catalogueDialog.dialogVisible = true
      if (type === 2) {
        this.$refs.catalogueDialog.form.id = data.id
        this.$refs.catalogueDialog.form.parentId = data.parentId
        this.$refs.catalogueDialog.form.libraryName = data.label
      } else if (data) {
        this.$refs.catalogueDialog.form.parentId = data.id
        this.$refs.catalogueDialog.form.parentName = data.label
      } else {
        this.$refs.catalogueDialog.form.parentId = 0
      }
      this.optClassData = data
    },
    deleteTree(data, node) {
      this.$confirm('是否确认删除目录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        DeleteLibraryById({ id: data.id }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            const { parent } = node
            const children = parent.data.children || parent.data
            const index = children.findIndex(d => d.id === data.id)
            children.splice(index, 1)
            success(res.data.msg)
          } else {
            error(res.data.msg)
          }
        })
      }).catch(() => { })
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-item-wrap {
  max-height: 300px;
}

.field-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  padding-right: 8px;
}
.tree-operate {
  position: absolute;
  right: 0px;
}
.tag-active {
  background-color: #7367f0 !important;
  color: #fff !important;
  border: none !important;
  ::v-deep .el-tag__close {
    color: #fff !important;
  }
}
.label {
  display: inline-block;
  background-color: rgba(115, 103, 240, 0.12);
  border: 1px solid rgba(115, 103, 240, 0.12);
  margin: 5px;
  padding: 0 8px;
  border-radius: 3px;
  line-height: 20px;
  color: #7367f0;
}
</style>
