<template>
  <el-dialog title="添加标签"
             :visible.sync="dialogVisible"
             @close="dialogClose"
             width="620px">
    <el-form v-if="dialogVisible"
             ref="form"
             v-loading="loading"
             :model="form"
             class="field-class-save-form"
             label-width="100px">

      <el-form-item label="标签名称"
                    prop="labelName"
                    :rules="[ { required: true, message: '标签名称不能为空', trigger: 'change' }]">
        <el-input v-model="form.labelName"
                  type="text"
                  maxlength="10" />
      </el-form-item>

    </el-form>
    <div slot="footer"
         class="dialog-footer">
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button type="primary"
                 :disabled="loading"
                 @click="submitForm">
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { CreateLabel } from '@/api/regime/regime'
import { error, success } from '@core/utils/utils'

export default {
  data () {
    return {
      dialogVisible: false,
      loading: false,
      form: {
        labelName: ''
      },
    }
  },
  methods: {
    submitForm () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const formData = new FormData()
          formData.append('labelName', this.form.labelName)
          CreateLabel(formData).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.dialogVisible = false
              this.$emit('saveLabel')
            } else {
              error(resData.msg)
            }
          })

        } else {
          return false
        }
      })
    },
    dialogClose () {
      this.form = {
        labelName: ''
      }
      this.$refs.form.resetFields()
    },
  },
}
</script>

<style scoped>
</style>
